import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import moment from 'moment'

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY hh:mm')
    }
});

Vue.config.productionTip = false
Vue.use(Vuesax, {
  theme: {
    colors: {
      primary: '#9B0E1C',
    }
  }
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
