import axios from "axios";

export const https = (baseURL = "") => {
  const hlg = "";
  const instance = axios.create({
    baseURL: `https://ggcs${hlg}.ambevaguasclarasdosul.com.br/api${baseURL}`,
    headers: {
      "x-api-key":
        "cfcc98ca8c75fcf4858b1c5afd9e0d6244e1d549a113a57f0b5612a70b82f191",
    },
  });
  return instance;
};
