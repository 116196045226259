<template>
  <div class="BuffetsAgenda">
    <h3>Cardápio da Semana ({{ weekLabel }}) - Versão 1.0.0</h3>
    <vs-alert
      title="Erro ao buscar o cardápio da semana"
      class="margin"
      color="danger"
      v-if="error"
    >
      {{ error }}
    </vs-alert>
    <vs-row vs-justify="center">
      <h1 class="no-agenda" v-if="hasAgenda">Não há Cardápio</h1>
      <vs-col v-else vs-w="3" v-for="(agenda, index) in agendas" :key="index">
        <div v-if="index == 7">
          <vs-card class="choosable-card">
            <div slot="header">NPS Refeitório Semanal</div>
            <table>
              <tr>
                <th>Limpeza</th>
                <th>Serviço:</th>
                <!-- <th>Covid:</th> -->
              </tr>
              <tr>
                <td>
                  <div v-bind:class="styleNPS(nps.clean)">
                    <strong>{{ formatNPS(nps.clean) }}</strong>
                  </div>
                </td>
                <td>
                  <div v-bind:class="styleNPS(nps.service)">
                    <strong>{{ formatNPS(nps.service) }}</strong>
                  </div>
                </td>
                <!-- <td>
                  <div v-bind:class="styleNPS(nps.covid)">
                    <strong>{{ formatNPS(nps.covid) }}</strong>
                  </div>
                </td> -->
              </tr>
            </table>
          </vs-card>
        </div>

        <div class="card-item-day" v-else>
          <vs-card class="choosable-card">
            <div slot="header">
              <span
                class="week-day-title"
                v-bind:class="{ today: index == today }"
                >{{ daysOfWeek[index] }}
              </span>
            </div>
            <div class="spacing-children">
              <p class="text">
                <strong>Pratos:</strong> {{ agenda.plate_1 }},
                {{ agenda.plate_2 }}, {{ agenda.plate_3 }}
              </p>
              <p class="text">
                <strong>Guarnição:</strong> {{ agenda.garrison }}
              </p>
              <p class="text">
                <strong>Sobremesa:</strong> {{ agenda.dessert }}
              </p>
              <!-- <p class="text"><strong>Sopa:</strong> {{ agenda.soup }}</p> -->
              <p class="text">
                <strong>Salada:</strong>
                {{ agenda.salad != null ? agenda.salad : "N/A" }}
              </p>
              <p class="text">
                <strong>Lights:</strong> {{ agenda.light_1 }},
                {{ agenda.light_2 }}
              </p>
              <div>
                <strong>NPS:</strong>
                <table>
                  <tr>
                    <th>Prato Principal</th>
                    <th>Guarnição</th>
                    <th>Sobremesa</th>
                    <th>Salada</th>
                    <th>Café manhã</th>
                  </tr>
                  <tr>
                    <td>
                      <div v-bind:class="styleNPS(agenda.nps.main_plate)">
                        <strong>{{ formatNPS(agenda.nps.main_plate) }}</strong>
                      </div>
                    </td>
                    <td>
                      <div v-bind:class="styleNPS(agenda.nps.garrison)">
                        <strong>{{ formatNPS(agenda.nps.garrison) }}</strong>
                      </div>
                    </td>
                    <td>
                      <div v-bind:class="styleNPS(agenda.nps.dessert)">
                        <strong>{{ formatNPS(agenda.nps.dessert) }}</strong>
                      </div>
                    </td>
                    <td>
                      <div v-bind:class="styleNPS(agenda.nps.salad)">
                        <strong>{{ formatNPS(agenda.nps.salad) }}</strong>
                      </div>
                    </td>
                    <td>
                      <div v-bind:class="styleNPS(agenda.nps.breakfast)">
                        <strong>{{ formatNPS(agenda.nps.breakfast) }}</strong>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </vs-card>
        </div>
      </vs-col>

      <vs-card class="choosable-card">
        <div slot="header">
          <h3>Mural de recado</h3>
        </div>

        <div>
          <h3 class="text">{{ note.title }}</h3>
          <p class="text">{{ note.description }}</p>
          <p class="text">Criado em {{ note.created_at | formatDate }}</p>
        </div>
      </vs-card>

      <vs-card class="choosable-card">
        <figure>
          <img
            :style="{ width: '50px', height: '50px' }"
            src="../assets/logo_ambev.png"
            alt="logo Cervejaria Ambev Águas Claras do Sul"
          />
          <img
            :style="{ width: '57.5px', height: '47.5px' }"
            src="../assets/logo_techcs.png"
            alt="logo TechCS"
          />
          <img
            :style="{ width: '50px', height: '50px', 'margin-right': '10px' }"
            src="../assets/logo_gg.png"
            alt="logo Mobfeel"
          />
          <img
            :style="{ width: '52.5px', height: '42.5px' }"
            src="../assets/logo_mobfeel_v.png"
            alt="logo Mobfeel"
          />
        </figure>
      </vs-card>
    </vs-row>
  </div>
</template>

<script>
import BuffetsAgendaService from "../services/buffetsAgenda";
export default {
  name: "BuffetsAgenda",
  data: () => ({
    nps: {},
    agendas: [],
    notes: [],
    note: {},
    error: null,
    weekLabel: "",
    daysOfWeek: [
      "SEGUNDA",
      "TERÇA",
      "QUARTA",
      "QUINTA",
      "SEXTA",
      "SÁBADO",
      "DOMINGO",
    ],
    today: -1,
  }),
  methods: {
    fetch() {
      //this.setToday();
      this.$vs.loading();
      new BuffetsAgendaService()
        .getBuffetsAgenda()
        .then(({ data }) => {
          const agenda = Array.isArray(data.result.week)
            ? data.result.week
            : [];
          
          if (agenda.length == 8) {
            agenda.pop();
            
          }
          this.agendas = agenda;

          if (this.agendas.length > 0) {
            const initial = this.agendas[0].date.substring(8, 10);
            const final = this.agendas[6].date.substring(8, 10);

            this.weekLabel = `${initial} ao ${final}`;
          }

          this.agendas.push({}); // general NPS
          this.notes = Array.isArray(data.result.notes)
            ? data.result.notes
            : [];

          const note = this.notes[this.notes.length - 1];
          this.note = note;
          this.nps = data.result.nps;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data.error;
          }
          this.$vs.loading.close();
        });
    },
    setToday() {
      const day = new Date().getDay();
      switch (day) {
        case 0:
          this.today = 6;
          break;
        default:
          this.today = day - 1;
          break;
      }
    },
    formatNPS(nps) {
      return nps === null ? "S/ NPS" : `${nps}%`;
    },
    styleNPS(nps) {
      var result = ["nps-title"];

      if (nps) {
        if (nps >= 75 && nps <= 100) {
          result.push("nps-excelent");
        } else if (nps >= 50 && nps <= 74) {
          result.push("nps-verygood");
        } else if (nps >= 0 && nps <= 49) {
          result.push("nps-good");
        } else {
          result.push("nps-poor");
        }
      }

      return result;
    },
  },
  computed: {
    hasAgenda() {
      return this.agendas.length <= 1;
    },
  },
  mounted() {
    setInterval(this.fetch(), 3600 * 1000); // 1h
  },
};
</script>

<style scoped>
.BuffetsAgenda .card-item-day .text {
  font-size: 15px; /* 16px; */ /* 200px */
  text-align: left;
  height: 38px;
}

.BuffetsAgenda .card-item-day {
  padding-left: 2px;
  padding-right: 2px;
  text-align: left;
}

.today {
  color: #fc1e3b;
}

.nps-title {
  /* margin-top: 5px; */
  background-color: #ffb500;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  color: white;
  font-size: 13px;
  text-align: center;
  display: table-cell;
  overflow: hidden;
  vertical-align: middle;
}

.nps-excelent {
  background-color: #5476cd;
}

.nps-verygood {
  background-color: #00aba7;
}

.nps-good {
  background-color: #ffb500;
}

.nps-poor {
  background-color: #ed4c80;
}

.week-day-title {
  /* display: block; */
  text-align: left;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.spacing-children p {
  margin-top: 1px;
}

.no-agenda {
  text-align: center;
}

table {
  text-align: left;
}

table tr td {
  padding-left: 10px;
}
</style>
