import { https } from './api'

export default class {
  constructor() {
    this.instance = https('/refectory')
  }

  getBuffetsAgenda = () => {
    return this.instance({
      method: 'GET',
      url: ''
    });
  }
}
