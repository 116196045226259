import { render, staticRenderFns } from "./BuffetsAgenda.vue?vue&type=template&id=038aee8e&scoped=true&"
import script from "./BuffetsAgenda.vue?vue&type=script&lang=js&"
export * from "./BuffetsAgenda.vue?vue&type=script&lang=js&"
import style0 from "./BuffetsAgenda.vue?vue&type=style&index=0&id=038aee8e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "038aee8e",
  null
  
)

export default component.exports