import Vue from 'vue'
import VueRouter from 'vue-router'
import BuffetsAgenda from '../components/BuffetsAgenda'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    //base: 'http://localhost:8080',
    routes: [
        {
            path:'/',
            component: BuffetsAgenda,
        }
    ]
    })

export default router
